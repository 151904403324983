//const host='http://localhost:8080/quicolynk-ws/api/' 
const host='https://wildfly.quicornac.net:8443/quicolynk-ws/api/'   
const host_jasper='https://juicy.quicornac.net:8443'

const Apis = {
    getOrigenes:host+'informes/origenes/',
    getBodegas:host+'informes/bodegas/',
    getItems:host+'informes/productos/',
    getLotes:host+'informes/lotes/',
    getPNCs:host+'informes/master/',
    getPNCDetail:host+'informes/detail/',
    getDestinos:host+'informes/destinos/',
    getPermisosPNC:host+'informes/permisos/',
    login:host+'usuarios/login/',
    saveInforme:host+'informes/save/',
    deleteSolicitud:host+'informes/delete/',
    editarSolicitud:host+'informes/update/EDITAR/',
    enviarSolicitud:host+'informes/update/ENVIAR/',
    transferirSolcitud:host+'informes/update/REVISAR/',
    subirInforme:host+'informes/update/SUBIR/',
    aprobar_audi:host+'informes/update/AUT_AUDI/',
    aprobar_gg:host+'informes/update/AUT_GG/',
    reporte_solicitud_reproceso:host_jasper+'/jasperserver/rest_v2/reports/Reports/solicitud_reproceso.pdf',
    reporte_journal_inventario:host_jasper+'/jasperserver/rest_v2/reports/Reports/journal_inv.pdf',
    getInforme:host+'informes/informe/',
    getActa:host+'informes/actapdf/',
    rechazarSolicitudCali:host+'informes/update/RECHAZAR_CALI/',
    rechazarSolicitudAudi:host+'informes/update/RECHAZAR_AUDI/',
    rechazarSolicitudAudiTransfer:host+'informes/reversar_transferencia/',
    rechazarSolicitudGG:host+'informes/update/RECHAZAR_GG/',
    subirActa:host+'informes/update/CONSUMO/',
    getDashboard:host+'informes/dashboard/',
    /*********** COA ******** */
    getParametrosInfoCOA: host+'coa/parametros_info/',
    getParametrosCOA: host+'coa/parametros/',
    getStockCodesCOA: host+'coa/stockcodes/',
    saveCOA: host+'coa/save/',
    editCOA: host+'coa/editar/',
    getListadoCOAS: host+'coa/listar/',
    reporte_coa_en:host_jasper+'/jasperserver/rest_v2/reports/Reports/coa.pdf',
    reporte_coa_remarks:host_jasper+'/jasperserver/rest_v2/reports/Reports/coa_remarks.pdf',
    getCOAdetail:host+'coa/detail/',
    getBodegasCOA:host+'coa/bodegas/',
    getItemsCOA:host+'coa/productos/',
    enviarCOA:host+'coa/enviar/',
    eliminarCOA:host+'coa/eliminar/',
    getPermisosCOA:host+'coa/permisos/',
    aprobarCOA:host+'coa/aprobar',
    rechazarCOA:host+'coa/rechazar',
    getTiposProductos: host + 'coa/tipos_producto',
    getClientesCOA: host + 'coa/clientes/',
    crearCOA: host + 'coa/saveRemarks/',
    getProductosCOA: host + 'coa/productos_lote/',
    getCOA_certificados: host+'coa/listar_certificados/',
    getBranchCOA: host+'coa/branch/',
    getDashboardCoaProductClass : host + 'coa/dashboard_coa_tipo_producto/',
    // MODULO AP
    getClasesProveedor: host+'ap/clase_proveedor/',
    getProductosCOA_QP:'http://192.168.50.20:8801/sdc/v1/product/all_batchs_data',
    getTokenQP:'http://192.168.50.20:8801/siscori-api-authentication-v4/auth/authenticate',
    getDataCoaQP:'http://192.168.50.20:8801/sdc/v1/product/data-coa',
    reporte_coa_qp:host_jasper+'/jasperserver/rest_v2/reports/Reports/coa_peru.pdf'
};

export default Apis;
